import BannerMob from '@/assets/img/banner/img_online_fieldtrip_banner_mob.jpg';
import BannerPc from '@/assets/img/banner/img_online_fieldtrip_banner_pc.jpg';
import iconFacebook from '@/assets/img/join/ico_facebook@2x.png';
import iconGoogle from '@/assets/img/join/ico_google@2x.png';
import iconNaver from '@/assets/img/join/ico_naver@2x.png';
import Button from '@/components/Button';
import Container from '@/components/Container';
import ErrorMessage from '@/components/Form/ErrorMessage';
import FormGroup from '@/components/Form/FormGroup';
import Col from '@/components/Grid/Col';
import Row from '@/components/Grid/Row';
import HR from '@/components/HR';
import Image from '@/components/Image';
import InputCheckbox from '@/components/Input/InputCheckbox';
import InputPassword from '@/components/Input/InputPassword';
import InputText from '@/components/Input/InputText';
import LinkSafe from '@/components/LinkSafe';
import NaverLogin, {
  NaverSuccessResult,
} from '@/components/SocialLogin/NaverLogin';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithoutTitle from '@/layouts/LayoutWithoutTitle';
import {
  FormContainer,
  PageTitle,
  SocialButton,
  SocialButtonText,
} from '@/page-blocks/auth/AuthCommon';
import LocalStorage from '@/services/LocalStorage';
import { usePopupStore } from '@/stores/PopupStore';
import { useUserStore } from '@/stores/UserStore';
import { useGoogleLogin } from '@react-oauth/google';
import dayjs from 'dayjs';
import { Link, navigate, PageProps } from 'gatsby';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { FC, useCallback, useEffect } from 'react';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { SubmitHandler, useForm } from 'react-hook-form';
import styled from 'styled-components';

const BannerContainer = styled(Container)`
  padding-top: 50px;
  padding-left: 0px;
  padding-right: 0px;
`;

interface LoginFormData {
  loginId: string;
  loginPw: string;
  isSavingId: boolean;
}

const Login: FC<PageProps> = observer(({ location }) => {
  const { redirectTo } = queryString.parse(location.search);
  const hashStr = location?.hash == null ? `` : location?.hash;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormData>({
    defaultValues: {
      loginId: LocalStorage.getItem(`savedLoginId`) || ``,
      isSavingId: !!LocalStorage.getItem(`savedLoginId`),
    },
  });
  const userStore = useUserStore();
  const popupStore = usePopupStore();

  const navigateNext = useCallback(() => {
    // redirectTo url을 받아와서 로그인후 넘어가기, default는 홈
    navigate(
      (((redirectTo == null ? `` : redirectTo) + hashStr) as string) || `/`,
      {
        state: {
          // 개인정보수정 등 재인증이 필요한 구간에서 인증 skip하기 위함
          skipAuth: true,
        },
      },
    );
  }, [hashStr, redirectTo]);

  // 일반 로그인 (폼 submit시)
  const onSubmit: SubmitHandler<LoginFormData> = async ({
    loginId,
    loginPw,
    isSavingId,
  }) => {
    // 아이디 저장
    if (isSavingId) {
      LocalStorage.setItem(`savedLoginId`, loginId.trim());
    } else {
      LocalStorage.removeItem(`savedLoginId`);
    }

    const error = await userStore.login({ loginId, loginPw });
    if (error) {
      popupStore.show(error);
    }
  };

  // 네이버 로그인
  const loginWithNaver = useCallback(
    async (result: NaverSuccessResult) => {
      const error = await userStore.loginSocial_test(
        `naver`,
        result.accessToken,
      );

      if (error && error.socialId) {
        popupStore.show(error.returnMessage, {
          onConfirm: () => navigate(`/auth/join`),
        });
      }
    },
    [popupStore, userStore],
  );

  // 페이스북 로그인
  const loginWithFacebook = useCallback(
    async ({ accessToken }: ReactFacebookLoginInfo) => {
      const error = await userStore.loginSocial_test(`facebook`, accessToken);
      if (error && error.socialId) {
        popupStore.show(error.returnMessage, {
          onConfirm: () => navigate(`/auth/join`),
        });
      }
    },
    [popupStore, userStore],
  );

  // 구글 로그인
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (res) => {
      const error = await userStore.loginSocial_test(`google`, res.code);
      if (error && error.socialId) {
        popupStore.show(error.returnMessage, {
          onConfirm: () => navigate(`/auth/join`),
        });
      }
    },
    // eslint-disable-next-line no-console
    onError: (e) => console.error(e),
    flow: 'auth-code',
  });

  useEffect(() => {
    // 로그인 되었으면 이동
    if (userStore.user) {
      navigateNext();
    }
  }, [navigateNext, userStore.user]);

  return (
    <LayoutWithoutTitle location={location}>
      <FormContainer>
        <PageTitle>
          <h2>로그인 테스트</h2>
        </PageTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Row>
              <InputText
                name="loginId"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                })}
                placeholder="아이디"
              />
            </Row>
            {errors.loginId && (
              <ErrorMessage>{errors.loginId.message}</ErrorMessage>
            )}
            {` `}
            <Row
              css={`
                margin-top: 16px;
              `}
            >
              <InputPassword
                name="loginPw"
                ref={register({
                  required: {
                    value: true,
                    message: `필수 입력입니다`,
                  },
                })}
                placeholder="비밀번호"
              />
            </Row>
            {errors.loginPw && (
              <ErrorMessage>{errors.loginPw.message}</ErrorMessage>
            )}
            <Row
              css={`
                margin-top: 12px;
              `}
            >
              <InputCheckbox
                label="아이디저장"
                name="isSavingId"
                ref={register}
              />
              <Link to="/auth/find-id" className="gray-underline">
                아이디/비밀번호 찾기
              </Link>
            </Row>
          </FormGroup>
          <Button type="submit" full>
            로그인
          </Button>

          <FormGroup
            css={`
              margin-top: 38px;
            `}
          >
            <Row>회원이 아니시라면, 지금 회원에 가입하세요.</Row>
            <Row
              css={`
                margin-top: 14px;
              `}
            >
              <Button
                type="button"
                full
                outline
                onClick={() => navigate(`/auth/join`)}
              >
                회원가입
              </Button>
            </Row>
          </FormGroup>
          <HR
            css={`
              margin: 40px 0;
            `}
          />
          <h3>
            <Tit
              size="s4"
              css={`
                line-height: 1.6;
                letter-spacing: -1.2px;
                ${breakpoint(`mobile`)} {
                  letter-spacing: -0.8px;
                }
              `}
            >
              SNS 계정으로 간편 로그인 하기
            </Tit>
          </h3>
          <Row
            css={`
              margin-top: 30px;
            `}
          >
            <Col align="center">
              <NaverLogin
                clientId={process.env.NAVER_LOGIN_CLIENT_ID || ``}
                callbackUrl={`${process.env.SELF_URL}/auth/logintest`}
                render={(buttonProps) => (
                  <SocialButton {...buttonProps}>
                    <img src={iconNaver} alt="네이버" width="60" height="60" />
                    <br />
                    <SocialButtonText>네이버</SocialButtonText>
                  </SocialButton>
                )}
                onSuccess={loginWithNaver}
              />
            </Col>
            <Col align="center">
              <FacebookLogin
                appId={process.env.FACEBOOK_LOGIN_APP_ID || ``}
                fields="name,email"
                disableMobileRedirect
                render={({ onClick }) => (
                  <SocialButton onClick={onClick}>
                    <img
                      src={iconFacebook}
                      alt="페이스북"
                      width="60"
                      height="60"
                    />
                    <br />
                    <SocialButtonText>페이스북</SocialButtonText>
                  </SocialButton>
                )}
                callback={loginWithFacebook}
              />
            </Col>
            <Col align="center">
              <SocialButton onClick={() => loginWithGoogle()}>
                <img src={iconGoogle} alt="구글" width="60" height="60" />
                <br />
                <SocialButtonText>구글</SocialButtonText>
              </SocialButton>
            </Col>
          </Row>
        </form>

        {/* 온라인 필드트립 2022.01.15까지만 운영 배너 */}
        {dayjs().isBefore(dayjs(`2022-01-15`)) ? (
          <BannerContainer>
            <LinkSafe
              to="https://www.unicef.or.kr/campaign/fieldtrip/intro.html?trackcode=ftrip21_login"
              target="_blank"
            >
              <Image pcSrc={BannerPc} mobileSrc={BannerMob} />
            </LinkSafe>
          </BannerContainer>
        ) : (
          ``
        )}
      </FormContainer>
    </LayoutWithoutTitle>
  );
});

export default Login;
