import { isBrowser } from '@/helpers/BrowserHelper';
import { FC, ReactElement, useEffect } from 'react';

// const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js`;
const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2-nopolyfill.js`;
// const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.1.js`;
// const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.1-nopolyfill.js`;
// const NAVER_ID_SDK_URL = `https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js`;
export interface NaverUser {
  email: string;
  name: string;
  id: string;
  // eslint-disable-next-line camelcase
  profile_image: string;
  age?: string;
  birthday?: string;
  gender?: string;
  nickname?: string;
}

export interface NaverSuccessResult {
  user?: NaverUser;
  accessToken: string;
}

interface IProps {
  clientId: string;
  callbackUrl: string;
  render: (props: any) => ReactElement;
  onSuccess: (result: NaverSuccessResult) => void;
  onFailure?: () => void;
}

/**
 * 이 함수는 브라우저 환경에서만 호출이 되야 한다. window 객체에 직접 접근한다.
 * @param props
 */
const initLoginButton = (props: Omit<IProps, 'render'>) => {
  if (!isBrowser) {
    return;
  }
  const { clientId, callbackUrl, onSuccess, onFailure } = props;
  const { naver } = window;
  if (!naver) {
    return;
  }

  const naverLogin = new naver.LoginWithNaverId({
    callbackUrl,
    clientId,
    isPopup: true,
    loginButton: { color: `green`, type: 3, height: 60 },
    callbackHandle: true,
  });
  naverLogin.init();
  naverLogin.logout();

  naver.closePopup = (winObj: any) => {
    winObj.close();
  };

  // 팝업에서 열릴때 실행됨
  naverLogin.getLoginStatus((status: any) => {
    window.naver.successCallback = onSuccess;
    window.naver.failureCallback = onFailure;

    if (status) {
      // 필수로 받아야 하는 정보가 있다면 reprompt 실행
      const email = naverLogin.user.getEmail();
      if (!email) {
        // eslint-disable-next-line no-alert
        alert(`이메일은 필수입니다. 정보제공에 동의해주세요.`);
        naverLogin.reprompt();
        return;
      }
      window.opener.naver.successCallback({
        user: naverLogin.user,
        accessToken: naverLogin.accessToken.accessToken,
      });
      naver.closePopup(window);
    }
    // window.opener?.naver.failureCallback();
    // naver.closePopup(window);
  });
};

const appendNaverButton = () => {
  if (document.querySelectorAll(`#naverIdLogin`).length === 0) {
    const naverId = document.createElement(`div`);
    naverId.id = `naverIdLogin`;
    naverId.style.position = `absolute`;
    naverId.style.top = `-10000px`;
    document.body.appendChild(naverId);
  }
};

const loadScript = (props: Omit<IProps, 'render'>) => {
  if (document.querySelectorAll(`#naver-login-sdk`).length === 0) {
    const script = document.createElement(`script`);
    script.id = `naver-login-sdk`;
    script.src = NAVER_ID_SDK_URL;
    script.onload = () => initLoginButton(props);
    document.head.appendChild(script);
  } else {
    // script가 이미 로드된 경우, init만 실행
    initLoginButton(props);
  }
};

const NaverLogin: FC<IProps> = ({ render, ...props }) => {
  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    appendNaverButton();
    loadScript(props);
  }, [props]);

  return render({
    onClick: () => {
      if (
        !document ||
        !(document as any).querySelector(`#naverIdLogin`).firstChild
      )
        return;
      const naverLoginButton: any = (document as any).querySelector(
        `#naverIdLogin`,
      ).firstChild;
      naverLoginButton.click();
    },
  });
};

export default NaverLogin;
